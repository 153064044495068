import {useFeature, useFeatureValue} from '@growthbook/growthbook-react';
import {Navigate, Route, Routes} from 'react-router-dom';
import AccountReady from 'screens/AccountReady/AccountReady';
import RunwayerCheckoutScreen from 'screens/CheckoutScreen/CheckoutScreen';
import {RiseCheckoutScreenV1} from 'screens/RiseCheckoutV1/screens/RiseCheckoutV1Screen/RiseCheckoutScreenV1';
import RiseSurvey from 'screens/RiseSurvey/RiseSurvey';
import {survey_config_profile_sequencing_nov_2024} from 'surveys/profile_sequencing_nov_2024';
import {survey_config_no_skip_auto_advance} from 'surveys/survey_config_no_skip_auto_advance';

export const controlSurveyConfig = survey_config_no_skip_auto_advance;
export const experimentSurveyConfig = survey_config_profile_sequencing_nov_2024;

const SurveyContainer = () => {
  const inExperiment = useFeature('web_age_illustration');
  const randomSurveyConfig = inExperiment.value ? experimentSurveyConfig : controlSurveyConfig;
  return <RiseSurvey surveyConfigJson={randomSurveyConfig} />;
};

const CheckoutScreenContainer = () => {
  const paywallExperimentEnabled = useFeatureValue('web_paywall_with_weekly', false);
  if (paywallExperimentEnabled) {
    return <RiseCheckoutScreenV1 variantName="rise-checkout-v1" />;
  }
  return <RunwayerCheckoutScreen variantName="runwayer-checkout" />;
};

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/start" replace />} />
      <Route index path="start" element={<SurveyContainer />} />
      <Route path="checkout" element={<CheckoutScreenContainer />} />
      <Route path="account-ready" element={<AccountReady />} />
    </Routes>
  );
};
