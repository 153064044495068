import {useEffect, useRef, useState} from 'react';
import {Hero} from 'screens/RiseCheckoutV1/components/Hero/Hero';
import {useSessionStorage} from 'usehooks-ts';
import Analytics from 'utils/Analytics';
import {getUserCountry} from 'utils/locale';
import {monthPlan, PlanInfo} from 'utils/stripePlans';
import {useEffectOnce} from 'utils/useEffectOnce';
import {BlackButton} from '../../components/CheckoutButtons/CheckoutButtons';
import {CommunityInvitation} from '../../components/CommunityInvitation/CommunityInvitation';
import {EditorsChoice} from '../../components/EditorsChoice/EditorsChoice';
import {MoneyBackGuarantee} from '../../components/MoneyBackGuarantee/MoneyBackGuarantee';
import {PaymentElement} from '../../components/PaymentElement/PaymentElement';
import {PlanDetails} from '../../components/PlanDetails/PlanDetails';
import {PlanSelection} from '../../components/PlanSelection/PlanSelection';
import {RiseFeatured} from '../../components/RiseFeatured/RiseFeatured';
import {RisePromise} from '../../components/RisePromise/RisePromise';
import {SavedDiscountBanner} from '../../components/SavedDiscountBanner/SavedDiscountBanner';
import {Testimonials} from '../../components/Testimonials/Testimonials';
import {AccountCreation} from '../AccountCreationScreen/AccountCreationScreen';
import styles from './RiseCheckoutScreenV1.module.css';
import {useCheckoutScreenAnalytics} from 'utils/useCheckoutScreenAnalytics';
import {PlanSelectionV2} from 'screens/RiseCheckoutV1/components/PlanSelectionV2/PlanSelectionV2';
import {useFeatureIsOn} from '@growthbook/growthbook-react';

type RiseCheckoutScreenV1Props = {
  variantName: string;
};

export function RiseCheckoutScreenV1({variantName}: RiseCheckoutScreenV1Props) {
  const {countryCode: country, userLanguages} = getUserCountry();
  const [showBanner, setShowBanner] = useState(false);
  const [subscription, setSubscription] = useSessionStorage('subscription', '');
  const [planToPurchase, setPlanToPurchase] = useState<PlanInfo>(monthPlan[country]);
  const showPricingGridVariant = useFeatureIsOn('paywall_pricing_grid_dec_2024');

  const paymentRef = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    Analytics.track('Country Code Determined', {country, userLanguages});
  });

  useCheckoutScreenAnalytics(variantName);

  useEffect(() => {
    Analytics.track('Plan Selected', {
      ...planToPurchase,
    });
  }, [planToPurchase]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowBanner(true);
      } else {
        setShowBanner(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const onGetMyPlanClick = () => {
    Analytics.track('Get My Plan Clicked');
    const bannerHeight = 73 + 20;
    window.scrollTo({top: (paymentRef.current?.offsetTop ?? 0) - bannerHeight, behavior: 'smooth'});
  };

  if (subscription) {
    return <AccountCreation />;
  }

  return (
    <div className={styles.riseCheckoutContainer} data-testid="screen-checkout-rise">
      <div style={{marginBottom: '24px'}}>
        <Hero />
      </div>
      <div style={{marginBottom: '54px'}}>
        {showPricingGridVariant ? (
          <PlanSelectionV2
            selectedPlan={planToPurchase}
            onPriceSelection={plan => setPlanToPurchase(plan)}
            onButtonClick={onGetMyPlanClick}
          />
        ) : (
          <PlanSelection
            selectedPlan={planToPurchase}
            onPriceSelection={plan => setPlanToPurchase(plan)}
            onButtonClick={onGetMyPlanClick}
          />
        )}
      </div>
      <div style={{marginBottom: '54px'}}>
        <EditorsChoice />
      </div>
      <div style={{marginBottom: '54px'}}>
        <Testimonials />
      </div>
      <div style={{marginBottom: '54px'}}>
        <CommunityInvitation />
      </div>
      <div style={{marginBottom: '54px'}}>
        <BlackButton title="Get my plan" onClick={onGetMyPlanClick} />
      </div>
      <div style={{marginBottom: '54px'}}>
        <RiseFeatured />
      </div>
      <div style={{marginBottom: '54px'}}>
        <MoneyBackGuarantee />
      </div>
      <div style={{marginBottom: '54px'}}>
        <RisePromise onButtonClick={onGetMyPlanClick} />
      </div>
      <div style={{marginBottom: '54px'}}>
        <PlanDetails selectedPlan={planToPurchase} />
      </div>
      <div ref={paymentRef} style={{marginBottom: '10rem'}}>
        <PaymentElement
          onSuccess={(subscription: string) => setSubscription(subscription)}
          planToPurchase={planToPurchase}
        />
      </div>

      <SavedDiscountBanner show={showBanner} onButtonClick={onGetMyPlanClick} />
    </div>
  );
}
