import styles from './PlanOption.module.css';

type PlanOptionProps = {
  index: number;
  selected: boolean;
  offerDetails: string;
  currencySymbol: string;
  pricePerDay: number;
  planPrice?: number;
  planIntroPrice: number;
  badgeText?: string;
  planTitle: string;
  onClick: () => void;
};

export const PlanOption = ({
  selected,
  offerDetails,
  currencySymbol,
  pricePerDay,
  planPrice,
  planIntroPrice,
  badgeText,
  planTitle,
  onClick,
}: PlanOptionProps) => {
  const splitPrice = pricePerDay.toFixed(2).toString().split('.');

  return (
    <div className={`${styles.gradientBorderBox} ${selected ? styles.selected : ''}`}>
      <div className={`${styles.container}`} onClick={onClick}>
        {badgeText ? <div className={styles.badge}>{badgeText}</div> : null}
        <div className={styles.pricingContainer}>
          <div className={styles.planContainer}>
            <div className={styles.planLabel}>{planTitle}</div>
            <div className={styles.priceContainer}>
              {planPrice ? (
                <span className={styles.price}>
                  {currencySymbol}
                  {planPrice}
                </span>
              ) : null}

              <span className={styles.priceIntro}>
                {currencySymbol}
                {planIntroPrice}
              </span>
            </div>
          </div>
          <div className={styles.perDayContainer} style={{marginTop: badgeText ? 8 : 0}}>
            <div className={styles.currency}>{currencySymbol}</div>
            <div className={styles.dollars}>{splitPrice[0]}</div>
            <div>
              <div className={styles.cents}>{splitPrice[1]}</div>
              <div className={styles.perDayLabel}>per day</div>
            </div>
          </div>
        </div>

        <div className={styles.offerDetails}>{offerDetails}</div>
      </div>
    </div>
  );
};
