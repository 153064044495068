import React from 'react';

import styles from './RiseInterstitialTemplate.module.css';
import {RiseInterstitialProps} from './RiseInterstitialBase';
import RiseSurveyInterstitialControls from '../RiseSurveyControls/RiseSurveyInterstitialControls';

export type RiseInterstitialTemplateProps = {
  preHeaderText?: string;
  headerText?: string;
  bodyText?: string;
  heroImage?: React.ReactNode;
  secondaryImage?: React.ReactNode;
  continueButtonTitle?: string;
  onClick?: () => void;
} & RiseInterstitialProps;

const RiseInterstitialTemplate: React.FC<RiseInterstitialTemplateProps> = ({
  preHeaderText,
  headerText,
  bodyText,
  heroImage,
  secondaryImage,
  continueButtonTitle,
  onClick,
}) => {
  return (
    <div className={styles.container}>
      {preHeaderText ? (
        <div className={styles.preHeaderText}>
          <div dangerouslySetInnerHTML={{__html: preHeaderText.replaceAll(/[\s\u200B]+/g, ' ')}} />
        </div>
      ) : null}
      {headerText ? (
        <div className={styles.headerText}>
          <div dangerouslySetInnerHTML={{__html: headerText.replaceAll(/[\s\u200B]+/g, ' ')}} />
        </div>
      ) : null}
      {heroImage ? <div className={styles.heroImage}>{heroImage}</div> : null}
      {bodyText ? (
        <div className={styles.bodyText}>
          <div dangerouslySetInnerHTML={{__html: bodyText.replaceAll(/\s+/g, ' ')}} />
        </div>
      ) : null}
      {secondaryImage ? <div className={styles.secondaryImage}>{secondaryImage}</div> : null}
      <RiseSurveyInterstitialControls continueButtonTitle={continueButtonTitle} onClick={onClick} />
    </div>
  );
};
export default RiseInterstitialTemplate;
