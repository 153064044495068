import {PageModel} from 'survey-core';
import {
  QUESTION_RISE_INTERSTITIAL,
  RiseInterstitialProps,
} from '../components/RiseInterstitialTemplate/RiseInterstitialBase';
import {RiseInterstitialPage} from '../../../types';

/**
 * Creates an interstitial page
 *
 */
export type InterstitialOptions = {
  name: string;
  isQuestion?: boolean;
  component: React.FC<RiseInterstitialProps & Record<string, any>>;
  visibleIf?: string;
  category?: string;
  hideProgressBar?: boolean;
  hideCategory?: boolean;
};
export const interstitial = ({
  name,
  component,
  visibleIf,
  category,
  hideProgressBar,
  hideCategory,
}: InterstitialOptions): RiseInterstitialPage => {
  return {
    visibleIf,
    riseProperties: {
      category,
      hideProgressBar,
      hideCategory,
    },
    elements: [
      {
        type: QUESTION_RISE_INTERSTITIAL,
        titleLocation: 'hidden',
        name,
        riseComponent: component,
      },
    ],
  };
};

/**
 * Looks for any questions on the page with the type of QUESTION_RISE_INTERSTITIAL
 */
export const isInterstitial = (page: PageModel) => {
  return page?.questions.some((q: any) => q?.jsonObj?.type === QUESTION_RISE_INTERSTITIAL);
};
