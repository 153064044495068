import {GrowthBookReady} from 'components/GrowthBookReady/GrowthBookReady';
import React, {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import LoadingScreen from 'screens/LoadingScreen/LoadingScreen';
import {PublicRoutes} from './PublicRoutes';
import {usePageTracking} from './usePageTracking';
import {QABar} from 'components/QATools/QABar';

const AdminRoutes = lazy(() => import('./AdminRoutes'));

const AppRoutes: React.FC = () => {
  usePageTracking();

  return (
    <Suspense fallback={<div />}>
      <GrowthBookReady
        requiredGBAttributes={['web_funnel_anon_id']}
        timeout={3000}
        fallback={<LoadingScreen delay={500} />}
      >
        <QABar />
        <Routes>
          <Route path="/*" element={<PublicRoutes />} />
          <Route path="/devqatools/*" element={<AdminRoutes />} />
        </Routes>
      </GrowthBookReady>
    </Suspense>
  );
};

export default AppRoutes;
