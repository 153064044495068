import {RiseInterstitialPage, RiseSurveyConfig, RiseSurveyPage} from 'types';

/**
 * @note For dev use only
 * Do not use this in production code. It is untested and loosy goosey with the types.
 *
 * @description It grabs page definitions from the survey
 *  config so we can easily add them to a QA or Dev variant
 */
export const pickSurveyItem_UNSAFE = (name: string, surveyConfig: RiseSurveyConfig) => {
  return surveyConfig.pages.find(page => {
    // This ANY is gross, but a necessary evil for now.
    return (page.elements as any).find((element: any) => element.name === name);
  }) as RiseSurveyPage | RiseInterstitialPage;
};
