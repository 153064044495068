import React from 'react';
import ModalSheet from 'components/ModalSheet/ModalSheet';
import ButtonSolid from 'components/Buttons/ButtonSolid';
import styles from './CircadianAlignmentModal.module.css';
import icon_circadian from '../assets/icon_circadian.svg';
import Analytics from 'utils/Analytics';
type Props = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};

function CircadianAlignmentModal({showModal, setShowModal}: Props) {
  return (
    <ModalSheet
      showModal={showModal}
      onCloseClick={() => {
        setShowModal(false);
      }}
      onShown={() => {
        Analytics.track('Profile Circadian Alignment Modal Shown');
      }}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={icon_circadian} className={styles.icon} />
          <div className={styles.title}>Circadian Alignment</div>
          <div className={styles.description}>
            Circadian alignment is the synchronization of your body’s internal clock with the day-night cycle. When you
            follow your natural rhythm, your energy levels and sleep patterns support better health and well-being.
            <br />
            <br />
            Circadian misalignment happens when our schedule is out of sync with the external environment, like staying
            up late, which can lead to fatigue, poor sleep, and health issues.
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <ButtonSolid title={'Got it'} onClick={() => setShowModal(false)} />
        </div>
      </div>
    </ModalSheet>
  );
}

export default CircadianAlignmentModal;
