import {useGrowthBook} from '@growthbook/growthbook-react';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Environment from 'utils/Environment';
import QA, {useExperimentQAHydration} from './QA';
import Analytics from 'utils/Analytics';

const QABarContent = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const growthbook = useGrowthBook();

  useExperimentQAHydration();

  useEffect(() => {
    if (show) {
      Analytics.track('Admin Bar Shown');
    }
  }, [show]);

  if (!show) {
    return (
      <div style={styles.container}>
        <div onClick={() => setShow(true)} style={{...styles.button, alignSelf: 'flex-start'}}>
          QA
        </div>
      </div>
    );
  }
  const features = Object.keys(growthbook.getFeatures()).map(key => ({
    key,
    value: growthbook.evalFeature(key).value,
    override: growthbook.evalFeature(key).source === 'override',
  }));

  return (
    <div style={styles.container}>
      <div style={{display: 'flex', flexDirection: 'row', gap: '4px'}}>
        <div onClick={() => setShow(false)} style={styles.button}>
          X
        </div>
        <div onClick={() => navigate('/devqatools')} style={styles.button}>
          Admin
        </div>
        {features.map(({key, value, override}) => (
          <div
            key={key}
            style={{...styles.feature, ...(value ? styles.enabled : styles.disabled)}}
            onClick={() => QA.forceFeature(key, !value)}
          >
            {key}: {String(value)}
            {override ? <div style={styles.override}>*</div> : null}
          </div>
        ))}
      </div>
      <div style={{fontSize: '8px'}}>Note: This tool caused GB analytics to fire for each feature!</div>
    </div>
  );
};

export const QABar = () => {
  if (Environment.isTest) {
    return <QABarContent />;
  }
  return null;
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    fontSize: '8px',
    flexWrap: 'wrap',
    userSelect: 'none',
    zIndex: 1000,
  },
  feature: {
    display: 'flex',
    backgroundColor: '#ddd',
    padding: '2px 4px',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#fff',
    alignItems: 'center',
    position: 'relative',
  },
  enabled: {
    backgroundColor: '#0ea45e',
  },
  disabled: {
    backgroundColor: '#c34531',
  },
  button: {
    fontSize: '12px',
    backgroundColor: '#ddd',
    padding: '2px 4px',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#fff',
    minWidth: '20px',
    textAlign: 'center',
  },
  override: {
    fontSize: '20px',
    color: 'yellow',
    fontWeight: '900',
    lineHeight: '12px',
  },
};
