import {useEffect} from 'react';
import styles from './ModalSheet.module.css';

type Props = {
  showModal: boolean;
  onCloseClick: () => void;
  onShown?: () => void;
  children: React.ReactNode;
};

const CloseIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.636155 11.949C0.245631 12.3395 0.245631 12.9727 0.636155 13.3632C1.02668 13.7538 1.65984 13.7538 2.05037 13.3632L7.00009 8.4135L11.95 13.3634C12.3405 13.7539 12.9736 13.7539 13.3642 13.3634C13.7547 12.9728 13.7547 12.3397 13.3642 11.9491L8.41431 6.99929L13.3641 2.04952C13.7546 1.65899 13.7546 1.02583 13.3641 0.635305C12.9736 0.244781 12.3404 0.244781 11.9499 0.635305L7.00009 5.58508L2.05045 0.635437C1.65993 0.244913 1.02676 0.244911 0.63624 0.635437C0.245716 1.02596 0.245716 1.65913 0.63624 2.04965L5.58588 6.99929L0.636155 11.949Z"
      fill="#4F4F4F"
    />
  </svg>
);

function ModalSheet({showModal, onCloseClick, onShown, children}: Props) {
  useEffect(() => {
    if (onShown) {
      onShown();
    }
  }, [onShown]);

  if (!showModal) {
    return null;
  }

  return (
    <>
      <div className={`${styles.overlay}`} onClick={onCloseClick}></div>
      <div className={`${styles.container}`}>
        {children}
        <div className={styles.closeIcon} onClick={onCloseClick}>
          <CloseIcon />
        </div>
      </div>
    </>
  );
}

export default ModalSheet;
