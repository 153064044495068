export const getSleepDebtZoneFromMidpointOffsets = (
  bedtimeMidpointMidnightOffset: string,
  wakeMidpointMidnightOffset: string,
) => {
  // We if Skipped or "Varies" the value is an empty string
  if (bedtimeMidpointMidnightOffset === '' || wakeMidpointMidnightOffset === '') {
    return 'moderate';
  }
  const bedtime = parseFloat(bedtimeMidpointMidnightOffset);
  const wake = parseFloat(wakeMidpointMidnightOffset);
  /** From kellie
   * Calculate sleep duration from midpoints
   * Assume users need 8.5h
   * IF duration 9+ -> LOW
   * IF duration 7h -> MODERATE
   * IF <7h -> HIGH
   * IF Null => Moderate
   */
  const sleepDuration = bedtime + wake;
  if (sleepDuration >= 9) {
    return 'low';
  } else if (sleepDuration >= 7.5 || sleepDuration === Infinity) {
    return 'moderate';
  }
  return 'high';
};
