import {format} from 'date-fns';
import styles from './AnimatedCheckoutHeroChart.module.css';
import bgImage from './assets/bg.png';
import {EnergyLine} from './EnergyLine';
import {SleepDebtLine} from './SleepDebtLine';
import common from 'common.module.css';

type Props = {
  startDate: Date;
  endDate: Date;
};

function AnimatedCheckoutHeroChart({startDate, endDate}: Props) {
  return (
    <div className={styles.container}>
      <img src={bgImage} className={styles.bg} />
      <SleepDebtLine delay={0} />
      <EnergyLine delay={2300} />
      <div className={styles.xAxis}>
        <div className={common.heading5} style={{textAlign: 'left', marginLeft: '22px'}}>
          {format(startDate, 'MMM d')}
        </div>
        <div className={common.heading5} style={{textAlign: 'right', marginRight: '18px'}}>
          {format(endDate, 'MMM d')}
        </div>
      </div>
    </div>
  );
}

export default AnimatedCheckoutHeroChart;
