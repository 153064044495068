import React from 'react';
import {CompleteButton} from './CompleteButton';
import {NextButton} from './NextButton';
import {SkipButton} from './SkipButton';

export type RiseSurveyControlsProps = {
  isLastPage: boolean;
  isInterstitial: boolean;
  isAutoAdvance: boolean;
  isRequired: boolean;
};

export const RiseSurveyControls: React.FC<RiseSurveyControlsProps> = ({
  isLastPage,
  isInterstitial,
  isAutoAdvance,
  isRequired,
}) => {
  const shouldHideAllControls = isInterstitial && !isLastPage;
  const shouldShowCompleteButton = isLastPage && !isAutoAdvance;
  const shouldHideSkipButton = isRequired || shouldShowCompleteButton;
  const shouldHideNextButton = isAutoAdvance || shouldShowCompleteButton;

  if (shouldHideAllControls) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '16px',
      }}
    >
      {shouldShowCompleteButton ? <CompleteButton /> : null}
      {!shouldHideNextButton ? <NextButton /> : null}
      {!shouldHideSkipButton ? <SkipButton /> : null}
    </div>
  );
};

export default RiseSurveyControls;
