import checkmark from './assets/checkmark-circle-gray.svg';
import styles from './Hero.module.css';
import common from 'common.module.css';
import {addWeeks} from 'date-fns';

import {SurveyData} from 'screens/RiseSurvey/utils/surveyData';
import AnimatedCheckoutHeroChart from 'components/AnimatedCheckoutHeroChart/AnimatedCheckoutHeroChart';

const goalMap = {
  'fall asleep faster': 'falling asleep faster',
  'stay asleep throughout the night': 'staying asleep all night',
  'improve sleep quality': 'improving your sleep quality',
  'wake up refreshed': 'waking up refreshed',
  'reduce sleep debt': 'reducing Sleep Debt',
  _default: 'improving your sleep quality',
};
const goalMapHeadline = {
  'fall asleep faster': 'Fall asleep faster',
  'stay asleep through the night': 'Stay asleep through the night',
  'improve sleep quality': 'Improve your sleep quality',
  'wake up feeling refreshed': 'Wake up feeling refreshed',
  'feel more energized during the day': 'Feel more energized during the day',
  _default: 'Improve your sleep quality',
};

const ageMap = {
  'under 18': 'in their teens',
  '18-23': 'in their late teens or early 20s',
  '24-29': 'in their 20s',
  '30-39': 'in their 30s',
  '40-49': 'in their 40s',
  '50-59': 'in their 50s',
  '60+': 'over 60',
  _default: '',
};

const ageMapV2 = {
  '18-29': 'in their 20s',
  '30-39': 'in their 30s',
  '40-49': 'in their 40s',
  '50+': 'over 50',
  _default: '',
};

const genderMap = {
  male: 'men',
  female: 'women',
  _default: 'people',
};

const findInMap = (key: string, map: Record<string, string>) => {
  return map[key?.toLowerCase() as keyof typeof map] || map._default;
};

export const Hero = () => {
  const surveyData = SurveyData.read();
  const goal = findInMap(surveyData?.goal, goalMap);
  const goalHeader = findInMap(surveyData?.goal, goalMapHeadline).toLowerCase();
  const age = findInMap(surveyData?.age, ageMap) || findInMap(surveyData?.age, ageMapV2);
  const gender = findInMap(surveyData?.gender, genderMap);
  let name = surveyData?.person_name;
  name = name?.[0].toUpperCase() + name?.slice(1);
  const noAgeOrGender = (!surveyData?.gender && !surveyData?.age) || (gender === 'people' && age === '');
  const goalText = `Strategies for ${goal}`;
  const sleepDebtText = `Customized suggestions to reduce Sleep Debt and wake refreshed`;
  const insightText = `Sleep insights designed for ${noAgeOrGender ? 'you' : `${gender} ${age}`}`;

  return (
    <section>
      <div className={common.heading1} style={{paddingTop: 18}}>
        {name ? (
          // ? `${name}, your personalized plan to <span class="color-gray2">${goalHeader}</span> is ready`
          <span>
            {name}, your personalized plan to <span className="color-purple1">{goalHeader}</span> is ready
          </span>
        ) : (
          'Your personal plan is ready'
        )}
      </div>

      <AnimatedCheckoutHeroChart startDate={new Date()} endDate={addWeeks(new Date(), 3)} />

      <div className={styles.features}>
        <div className={styles.list}>
          <div className={styles.listItem}>
            <img src={checkmark} alt="Checkmark" className={styles.checkmark} />
            <div>{goalText}</div>
          </div>
          <div className={styles.listItem}>
            <img src={checkmark} alt="Checkmark" className={styles.checkmark} />
            <div>{sleepDebtText}</div>
          </div>
          <div className={styles.listItem}>
            <img src={checkmark} alt="Checkmark" className={styles.checkmark} />
            <div>{insightText}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
