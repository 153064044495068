import React from 'react';
import styles from './SocialProof.module.css';

const Star = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 107273">
      <path
        id="Star 4"
        d="M10.5001 1.6665L12.0675 7.84246L18.4256 7.4247L13.0362 10.8239L15.3983 16.7416L10.5001 12.6665L5.60187 16.7416L7.96393 10.8239L2.57461 7.4247L8.93265 7.84246L10.5001 1.6665Z"
        fill="#e7b63c"
      ></path>
    </g>
  </svg>
);

function SocialProof() {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.stars}>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </div>
        <div className={`${styles.cardTitle} card-title`}>320K+ positive reviews</div>
      </div>
      <div className={styles.review}>
        "The personalized sleep plan transformed my energy levels and productivity remarkably!"
        <br />
        <br />
        <span className={styles.boldText}>Emily S.</span>
      </div>
    </div>
  );
}

export default SocialProof;
