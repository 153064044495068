import arrow2 from 'images/arrow2.svg';
import logo from 'images/logo.svg';
import styles from './RiseHeader.module.css';
import sunRise from 'images/rise-sunrise-logo.png';
type Props = {
  backButton?: boolean;
  onBackClick?: () => void;
  enableCategoryHeader?: boolean;
  category?: string;
  firstPage?: boolean;
  hideCategory?: boolean;
};

export function RiseHeader({
  backButton = true,
  onBackClick,
  enableCategoryHeader = false,
  category,
  firstPage = false,
  hideCategory = false,
}: Props) {
  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        {backButton && onBackClick ? <img src={arrow2} alt="Back" onClick={onBackClick} /> : null}
      </div>
      <div className={styles.headerCenter}>
        {enableCategoryHeader ? (
          <div className={styles.categoryHeader}>
            <div className={styles.ribbon} style={{transform: firstPage ? 'translateY(0px)' : 'translateY(-40px)'}}>
              <img src={sunRise} alt="Rise" className={styles.sunLogo} style={{opacity: firstPage ? 1 : 0}} />
              <img src={logo} alt="Rise" className={styles.logo} />
              <div className={`${styles.category}`} style={{opacity: category && !firstPage && !hideCategory ? 1 : 0}}>
                {category}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.basicHeader}>
            <img src={logo} alt="Rise" className={styles.logo} />
          </div>
        )}
      </div>
      <div className={styles.headerRight} />
    </div>
  );
}
