export const survey_config_base = {
  progressBarShowPageTitles: false,
  showQuestionNumbers: 'off',
  showPrevButton: false,
  showNavigationButtons: 'none',
  navigateToUrl: '/checkout',
  showCompletedPage: false,
  showTitle: false,
  questionTitlePattern: 'numTitle', //hides asterisk on required questions
};
