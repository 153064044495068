import common from 'common.module.css';
import {getUserCountry} from 'utils/locale';
import {monthPlan, PlanInfo, quarterPlan, weekPlan} from 'utils/stripePlans';
import {BlackButton} from '../CheckoutButtons/CheckoutButtons';
import {OfferExpirationTimerRounded} from '../OfferExpirationTimer/OfferExpirationTimer';
import {PlanOption} from './components/PlanOption';
import styles from './PlanSelectionV2.module.css';

export const PlanSelectionV2 = ({
  selectedPlan,
  onPriceSelection,
  onButtonClick,
}: {
  selectedPlan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
  onButtonClick: () => void;
}) => {
  const {countryCode: locale} = getUserCountry();
  const weeklyPlan = weekPlan[locale];
  const monthlyPlan = monthPlan[locale];
  const quarterlyPlan = quarterPlan[locale];

  return (
    <section className={styles.container}>
      <div className={[common.superBold, common.heading1].join(' ')} style={{marginBottom: 0}}>
        Choose your plan
      </div>

      <OfferExpirationTimerRounded />
      <div className={styles.planSelectionContainer}>
        <PlanOption
          index={1}
          selected={selectedPlan.id === weeklyPlan.id}
          offerDetails={'$6.99 for the first week, renews monthly at $29.99. Cancel anytime.'}
          currencySymbol={weeklyPlan.label}
          pricePerDay={weeklyPlan.perDay}
          planIntroPrice={weeklyPlan.introPrice}
          planTitle="Weekly"
          onClick={() => onPriceSelection(weeklyPlan)}
        />
        <PlanOption
          index={2}
          selected={selectedPlan.id === monthlyPlan.id}
          offerDetails={'$19.99 for the first month, renews monthly at $29.99. Cancel anytime.'}
          currencySymbol={monthlyPlan.label}
          pricePerDay={monthlyPlan.perDay}
          planPrice={monthlyPlan.price}
          planIntroPrice={monthlyPlan.introPrice}
          badgeText="Most Popular"
          planTitle="Monthly"
          onClick={() => onPriceSelection(monthlyPlan)}
        />
        <PlanOption
          index={3}
          selected={selectedPlan.id === quarterlyPlan.id}
          offerDetails={'$29.99 for the first 3 months, renews quarterly at $49.99. Cancel anytime.'}
          currencySymbol={quarterlyPlan.label}
          pricePerDay={quarterlyPlan.perDay}
          planPrice={quarterlyPlan.price}
          planIntroPrice={quarterlyPlan.introPrice}
          badgeText="Best price"
          planTitle="Quarterly"
          onClick={() => onPriceSelection(quarterlyPlan)}
        />
        <div className={styles.planSelectionButton}>
          <BlackButton title="Continue" onClick={onButtonClick} />
        </div>
        <div className={[common.center, common.colorGray2, common.smallText].join(' ')}>
          Guaranteed safe checkout and
          <br />
          100% money-back guarantee
        </div>
      </div>
    </section>
  );
};
