import React, {useEffect, useState} from 'react';

type Props = {
  duration?: number;
  delay?: number;
};

const stepSize = 4;
const GreenCheckmark = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{marginRight: '1px'}}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.197 9.197C17.4899 8.9041 17.4899 8.42923 17.197 8.13634C16.9041 7.84344 16.4292 7.84344 16.1363 8.13634L10 14.2727L7.86366 12.1363C7.57077 11.8434 7.0959 11.8434 6.803 12.1363C6.51011 12.4292 6.51011 12.9041 6.803 13.197L9.46967 15.8637C9.76256 16.1566 10.2374 16.1566 10.5303 15.8637L17.197 9.197Z"
      fill="#00b38b"
    />
  </svg>
);
export const AnimatedCounter: React.FC<Props> = ({duration = 1000, delay = 0}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const tickDur = duration / (100 / stepSize);
    let interval: NodeJS.Timeout;
    function start() {
      setCount(stepSize);
      interval = setInterval(() => {
        setCount(prevCount => {
          if (prevCount < 100) {
            return prevCount + stepSize;
          } else {
            clearInterval(interval);
            return 100;
          }
        });
      }, tickDur);
    }
    const timeout = setTimeout(start, delay);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [duration, delay]);

  return <div>{count >= 100 ? <GreenCheckmark /> : `${count}%`}</div>;
};
